<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left :name="'feedback'"></user-left>
        <div class="user-right" ref="right">
          <div class="title-top"><strong>意见反馈</strong></div>
          <div class="form-box" style="min-height: 570px;">
            <div class="a-of-h">
              <div class="item-input">
                  <textarea rows="10" placeholder="请输入..."
                            v-model="feedback"></textarea>
              </div>
            </div>
            <button type="button" class="btn" @click="submitForm">提交</button>
          </div>
        </div>
      </div>
    </div>
    <div class="model" v-if="reply">
      <h2>提交成功</h2>
      <div class="model-content">
        <p style="padding-left:30px;text-indent: -30px;">
          感谢您给予的宝贵意见，如需进一步联络请拨打电话：15154208399（微信）。
        </p>
      </div>
      <div class="model-btn">
        <span @click="reply=false">关闭</span>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav'
  import FooterNav from '@/components/FooterNav'
  import UserLeft from '@/components/UserLeft'

  export default {
    name: 'user',
    data() {
      return {
        current: 'user',
        feedback: '',
        reply: false,
      }
    },
    methods: {
      submitForm: function () {
        if (!this.feedback) {
          this.$layer.msg('请输入您的意见或建议！')
          return false
        }
        let params = {
          content: this.feedback
        }
        this.$post('user/addFeedback', params).then(res => {
          this.$layer.msg(res.msg)
          if (res.code === 1) {
            this.feedback = ''
            this.reply = true
          }
        }).catch(response => {
          this.$layer.msg('网络异常，请稍后重试！')
        })
      }
    },
    components: {
      HeaderNav,
      FooterNav,
      UserLeft
    },
    mounted: function () {
    },
    created() {
    }
  }
</script>

<style lang="less" scoped>
  @import url('../assets/css/page/feedback');
</style>
